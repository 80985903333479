import { useEffect } from "react";
import { render } from "react-dom";
import App from "./App";
import "./styles/_global.scss";

const RenderComponent = () => {
  useEffect(() => {
    const cursorDot = document.querySelector(".cursor-dot");
    const cursorOutline = document.querySelector(".cursor-outline");

    const onMouseMove = event => {
      const positionY = `${event.clientY}px`;
      const positionX = `${event.clientX}px`;

      cursorDot.style.top = positionY;
      cursorDot.style.left = positionX;

      cursorOutline.animate(
        {
          top: positionY,
          left: positionX,
        },
        {
          duration: 300,
          fill: "forwards",
          easing: "ease",
        }
      );

      cursorOutline.style.top = event.pageY + "px";
      cursorOutline.style.left = event.pageX + "px";
    };

    window.addEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <>
      <div className="cursor-dot" />
      <div className="cursor-outline" />
      <App />
    </>
  );
};

render(<RenderComponent />, document.getElementById("root"));
